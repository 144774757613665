<template>
  <div></div>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { DataTableHeader } from "@/mixins/DataTableMixin";

@Component
export default class ColumnsSelect extends Vue {
  @VModel({ type: Array }) arSelected!: DataTableHeader[];

  @Prop(Array) readonly items!: DataTableHeader[];
  @Prop({ type: String, default: "report.header.columns" })
  readonly label!: string;
}
</script>
